export const colors = {
  primary: "#48dbb4",
  secondary: "#7850CD",
  success: "#118263",
  error: "#b70a0a",
  disabled: "#818181",
  brown: "#AC6E16",
  night: "#0A0806",
  black: "#000000",
  grey: "#C4C4C4",
  darkGrey: "#AAAAAA",
  lightGrey: "#E6E6E6",
  bgSuccess: "#D7F6EE",
  pending: "#FF9900",
  bgPending: "#F8E9D3",
  bgSecondary: "#EFE8FF",
  grey10: "#F7F7F7",
  grey20: "#E6E6E6",
  grey30: "#C4C4C4",
  grey40: "#AAAAAA",
  grey50: "#818181",
  grey70: "#4C4C4C",
  grey60: "#6D6D6D  ",
  mint20: "#C8F4E9",
  mint80: "#12C0A1",
  green30: "#D7F6EE",
  brandViolet100: "#52388A",
  brandViolet80: "#6946B4",
  brandViolet40: "#AE96E1",
  brandViolet20: "#D7CBF0",
  brandViolet10: "#F2EEFA",
  white: "#fff",
  yellowPrimary: "#FFF9F1",
  yellowSecondary: "#FF9900",
  yellow30: "#FFEFD9",
  red60: "#DE7171",
}
